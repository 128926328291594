// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-conditions-generales-de-vente-tsx": () => import("./../../../src/pages/conditions-generales-de-vente.tsx" /* webpackChunkName: "component---src-pages-conditions-generales-de-vente-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-green-philosophie-tsx": () => import("./../../../src/pages/green-philosophie.tsx" /* webpackChunkName: "component---src-pages-green-philosophie-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-pimp-my-remorque-tsx": () => import("./../../../src/pages/pimp-my-remorque.tsx" /* webpackChunkName: "component---src-pages-pimp-my-remorque-tsx" */),
  "component---src-pages-politique-confidentialite-tsx": () => import("./../../../src/pages/politique-confidentialite.tsx" /* webpackChunkName: "component---src-pages-politique-confidentialite-tsx" */),
  "component---src-pages-propos-tsx": () => import("./../../../src/pages/propos.tsx" /* webpackChunkName: "component---src-pages-propos-tsx" */),
  "component---src-pages-services-courrier-entreprise-tsx": () => import("./../../../src/pages/services/courrier-entreprise.tsx" /* webpackChunkName: "component---src-pages-services-courrier-entreprise-tsx" */),
  "component---src-pages-services-livraison-colis-tsx": () => import("./../../../src/pages/services/livraison-colis.tsx" /* webpackChunkName: "component---src-pages-services-livraison-colis-tsx" */),
  "component---src-pages-services-livraison-volumineuse-tsx": () => import("./../../../src/pages/services/livraison-volumineuse.tsx" /* webpackChunkName: "component---src-pages-services-livraison-volumineuse-tsx" */),
  "component---src-pages-services-transport-urgent-tsx": () => import("./../../../src/pages/services/transport-urgent.tsx" /* webpackChunkName: "component---src-pages-services-transport-urgent-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */),
  "component---src-pages-ui-kit-tsx": () => import("./../../../src/pages/ui-kit.tsx" /* webpackChunkName: "component---src-pages-ui-kit-tsx" */)
}

